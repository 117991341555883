<template>
<div>
<loading :active.sync="isLoading"></loading>
<Header />
<main>
  <div id="top">
    <div id="main-content">
      <div id="content-area" class="clearfix">
        <article>
          <div id="kv">
            <h1>マジェスティ <br class="sp">
              オーナーズクラブ</h1>
          </div>
          <main>
            <div class="btnAreaTop">
              <div class="btn btnLogin"><a href="login/" class="arrow" target="_blank">ログインページへ</a></div>
              <div class="btn btnRegister"><a class="arrow" id="openModal">マジェスティ オーナーズクラブに<br class="pc">登録する</a></div>
            </div>
            <p class="txt_center">※マルマン製品はマジェスティオーナーズクラブ入会の対象製品ではありません。</p>
  <div class="btnCam"><a href="/royale-2025/">New MAJESTY Royale <br class="sp">製品情報/キャンペーン情報</a></div>
<div class="btnBlank"><a href="https://www.majesty-golf.com" target="_blank" class="gold">ブランドサイトへ</a></div>
            
            <!-- modal start -->
            <section id="modalArea" class="modalArea">
              <div id="modalBg" class="modalBg"></div>
              <div class="modalWrapper">
                <div class="modalContents">
                  <div class="btnArea">
                    <div class="btn btnLogin"><a href="/login/ID/" class="arrow" target="_blank">既にハガキや旧サイトで製品登録済で<br>
                      オーナー番号をお持ちの方はこちら</a></div>
                    <div class="btn btnRegister"><a href="/login/customer/register/email" class="arrow" target="_blank">新規登録はこちら</a></div>
                  </div>
                </div>
                <div id="closeModal" class="closeModal"> × </div>
              </div>
            </section>
            <!-- modal end --> 
            
            <section id="section02">
              <div class="container2">
                <h2>マジェスティオーナーズクラブとは</h2>
                <p>マジェスティオーナーズクラブは、<br class="pc">MAJESTY のゴルフクラブを正規販売店でご購入、製品登録していただいた方のみ
<br class="pc">入会できる会員組織（入会金、年会費無料）です。<br>
                  様々な特典に加え、オーナー様とマジェスティゴルフ、<br class="pc">
                  またはオーナー様同士の交流の場として豊かなゴルフライフをご提供いたします。</p>
              </div>
            </section>
            <section id="section04">
              <div class="container2">
                <div class="ttl">
                  <h2>マジェスティ オーナー様向け<br class="sp">
                    特典のご案内</h2>
                </div>
                <div class="box">
                  <div class="item">
                    <div class="imgArea"><img src="/assets/images/top/img_about05.jpg"></div>
                    <div class="txtArea">
                      <div class="ttl_s"><i><img src="/assets/images/common/icon_box.svg"></i>
                        <h3>お得な情報・記念品をお届け</h3>
                      </div>
                      <p>・マジェスティ情報、試打会、クラブ相談会、メンテナンス会などお得な情報のご案内（ＤＭ・メール他）<br>
                        ・ホールインワン達成記念品＆エージシュート達成記念品（１回限り）のお届け<br>
                        <span class="txt_s">※申請にはゴルフコースが発行する証明書が必要です。</span></p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="imgArea"><img src="/assets/images/top/img_about01.jpg"></div>
                    <div class="txtArea">
                      <div class="ttl_s"><i><img src="/assets/images/common/icon_warranty.svg"></i>
                        <h3>保証期間延長</h3>
                      </div>
                      <p>ご購入いただいたクラブをご登録いただくとクラブの保証期間を延長いたします。<br>
                        （一部製品は除きます）</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="imgArea"><img src="/assets/images/top/img_about02.jpg"></div>
                    <div class="txtArea">
                      <div class="ttl_s"><i><img src="/assets/images/common/icon_flag.svg"></i>
                        <h3>オーナー様限定イベントへのご参加</h3>
                      </div>
                      <p>オーナー様限定のイベントをご用意しております。<br>
                        最新の開催予定から事後レポートまでご覧いただけます。</p>
                      <!--<p><a href="https://www.majesty-golf.com/jp/news/mjoc-competition-21th/" style="text-decoration: underline;" target="_blank">第21回「マジェスティオーナーズクラブコンペ」<br>参加者募集中！</a></p>--> 
                    </div>
                  </div>
                  <div class="item">
                    <div class="imgArea"><img src="/assets/images/top/img_about03.jpg"></div>
                    <div class="txtArea">
                      <div class="ttl_s"><i><img src="/assets/images/common/icon_golf.svg"></i>
                        <h3>試打クラブ 無料レンタルサービス</h3>
                      </div>
                      <p>オーナー様がご利用いただける、試打クラブの無料レンタルサービスをご用意しております。<br>
                        (ご購入クラブを登録後にご利用いただけます)</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="imgArea"><img src="/assets/images/top/img_about04.jpg"></div>
                    <div class="txtArea">
                      <div class="ttl_s"><i><img src="/assets/images/common/icon_fix.svg"></i>
                        <h3>プレミアムアフターサービス</h3>
                      </div>
                      <p>オーナー様の特典として、グリップの無料交換など、特別なアフターサービスをご用意しております。<br>
                        （製品により異なります）</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="btnAreaTop">
              <div class="btn btnLogin"><a href="https://majesty-golf.club/login/" class="arrow" target="_blank">ログインページへ</a></div>
              <div class="btn btnRegister"><a class="arrow" id="openModal2">マジェスティ オーナーズクラブに<br class="pc">登録する</a></div>
            </div>
            <p class="txt_center">※マルマン製品はマジェスティオーナーズクラブ入会の対象製品ではありません。</p>
	<div class="btnBlank"><a href="https://www.majesty-golf.com" target="_blank" class="gold">ブランドサイトへ</a></div>
 <div class="btnBlank"><a href="mailto:majesty_ownersclub@majesty-golf.com?subject=問い合わせ/画像製品登録&body=保証書に必要事項を記入し、所定欄にシリアル番号を添付しました。画像で製品登録します。%0A%0A店舗名／ご担当様氏名%0A%0A（画像を添付して送信）"　class="">店舗様専用</a></div>
            
            <!-- modal start -->
            <section id="modalArea2" class="modalArea2">
              <div id="modalBg2" class="modalBg"></div>
              <div class="modalWrapper">
                <div class="modalContents">
                  <div class="btnArea">
                    <div class="btn btnLogin"><a href="/login/ID/" class="arrow" target="_blank">既にハガキや旧サイトで製品登録済で<br>
                      オーナー番号をお持ちの方はこちら</a></div>
                    <div class="btn btnRegister"><a href="/login/customer/register/email" class="arrow" target="_blank">新規登録はこちら</a></div>
                  </div>
                </div>
                <div id="closeModal2" class="closeModal"> × </div>
              </div>
            </section>
            <!-- modal end --> 
            
          </main>
          
          <!-- .entry-content --> 
          
        </article>
        <!-- .et_pb_post --> 
        
        <!-- #left-area --> 
        
      </div>
      <!-- #content-area --> 
      
      <!-- .container --> 
      
    </div>
    <!-- #main-content --> 
  </div>
</main>
<Footer />
</div>
</template>

<script>
import Footer from '../footer'
import Header from '../header'
import Loading from 'vue-loading-overlay'

export default {
  data: function () {
    return {
      isLoading: false
    }
  },
  components: {
    Footer,
    Header,
    Loading
  },
  mounted: function () {
    const modalArea = document.getElementById('modalArea')
    const openModal = document.getElementById('openModal')
    const closeModal = document.getElementById('closeModal')
    const modalBg = document.getElementById('modalBg')
    const toggle = [openModal, closeModal, modalBg]

    for (let i = 0, len = toggle.length; i < len; i++) {
      toggle[i].addEventListener('click', function() {
        modalArea.classList.toggle('is-show')
      }, false)
    }

    const modalArea2 = document.getElementById('modalArea2')
    const openModal2 = document.getElementById('openModal2')
    const closeModal2 = document.getElementById('closeModal2')
    const modalBg2 = document.getElementById('modalBg2')
    const toggle2 = [openModal2, closeModal2, modalBg2]

    for (let i = 0, len = toggle2.length; i < len; i++) {
      toggle2[i].addEventListener('click',function() {
        modalArea2.classList.toggle('is-show')
      }, false)
    }
  }
}
</script>
